h1,
h2,
h3,
p,
ul,
ol,
dl {
  @include sans-serif(16, 25);
  margin-top: 25px;
}

h1,
h2,
h3 {
  font-weight: bold;
}

ul
li {
  list-style-type: none;
}

img {
  border: 0 none;
}

a {
  color: inherit;
}

.js-hide {
  display: none;
}

.js-show {
  display: block;
}
