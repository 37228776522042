.intro {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.intro a {
  text-decoration: none;
  border-bottom: 1px solid #333f48;
}

.intro .icon--01 {
  margin-top: 37px;
}

.intro a {
  position: relative;
  bottom: 10px;
}

.intro .icon--03 {
  width: 100%;
  margin-top: 8px;
}

.intro .icon--logo_charvet {
  width: 100%;
  height: auto;
}
