.icon--01 {
	width: 201px;
	height: 31px;
}

.icon--02 {
	width: 132px;
	height: 11px;
}

.icon--03 {
	width: 124px;
	height: 51px;
}

.icon--logo_charvet {
	width: 455px;
	height: 449px;
}

